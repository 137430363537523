import React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

import LandingCountries from "../components/LandingCountries";
import Head from "next/head";

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const LandingPage: React.FC = (props) => {
  return (
    <>
      <Head>
        <script async data-api="/_hive" src="/bee.js"></script>
      </Head>
      <Layout>
        <LandingCountries />
        <Footer />
      </Layout>
    </>
  );
};

export default LandingPage;
